#hero-1 {
	background-color: #0d0337;
	background-image: url('./images/hero.jpg');
	background-size: cover;
	background-position: center center;

	&.hero-section h2 {
		text-shadow: 0 2px 2px rgba(0, 0, 0, 0.5), 0 -1px 1px rgba(0, 0, 0, 0.25);
	}
}
