#hero-1 {
  background-color: #0d0337;
  background-image: url("hero.803908f7.jpg");
  background-size: cover;
  background-position: center center;
}
#hero-1.hero-section h2 {
  text-shadow: 0 2px 2px rgba(0, 0, 0, 0.5), 0 -1px 1px rgba(0, 0, 0, 0.25);
}
/*# sourceMappingURL=index.30bd74bc.css.map */
